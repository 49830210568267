import { mapGetters } from "vuex";

export var elementDataMixin = {
    props: ["elementData"],
    computed: {
        ...mapGetters(["trackRecord", "trackStatus"]),
        currentValue: {
            get: function () {
                try {
                    return this.trackRecord.data[this.elementData.prop]
                }
                catch (e) {
                    return null;
                }
            },
            set: function (value) {
                this.updateElementDataValue(this.elementData.prop, value, this.validate(value));
            }
        },
        disabled: function () {
            return this.trackStatus == "closed"
        }
    },
    methods: {
        updateElementDataValue(prop, value, valid) {
            this.$emit("on-event", {
                type: "element-value-update",
                payload: {
                    prop: prop,
                    value: value,
                    valid: valid != null ? valid : true,
                },
            });
        },
        validate(value) {
            return value != null
        },
        initialValue() {
            return null
        }
    },
    created() {
        try {
            let value = this.trackRecord.data[this.elementData.prop]
            if (value == null) this.updateElementDataValue(this.elementData.prop, this.initialValue(), false)
        } catch (error) {
            this.updateElementDataValue(this.elementData.prop, this.initialValue(), false)
        }
    }
}