import {
    fecthUserTrackRecord, sendUserTrackRecord, setUserTrackRecordToStorageData, fetchTrack
} from "../../data/track-data";
import { eventHandler } from "./handlers/event-handler"
import { validateTrackState } from "./handlers/state-handler";
import { analytics } from "../../plugins/firebase";

const actions = {
    async fetchTrack({ commit, getters }, trackID) {

        let trackRecord = await fecthUserTrackRecord(trackID, getters.user.data.uid);
        commit("SET_USER_TRACK_RECORD", trackRecord);

        let track = await fetchTrack(trackID);
        commit("SET_TRACK", { id: trackID, ...track });

        if (!getters.viewID)
            commit("SET_CURRENT_TRACK_VIEW_ID", track.data.context.entryPoint);

    },
    async fetchTrackData({ commit }, trackID) {
        let track = await fetchTrack(trackID);
        commit("SET_TRACK", { id: trackID, ...track });
    },
    async dispatchTrackEvent({ state, commit, getters }, event) {

        await eventHandler(event, commit, getters);

        if (event.endTrack && getters.trackStatus != "closed") {
            commit("SET_TRACK_STATUS", "closed")
            analytics.logEvent('completed_track', { id: getters.trackID })
        }

        await setUserTrackRecordToStorageData(getters.trackRecord, getters.trackID, getters.user.data.uid);

        if (event.sendData || event.endTrack)
            await sendUserTrackRecord(getters.trackRecord, getters.trackID, getters.userData.uid);

        validateTrackState(state, commit, getters);
    },
}
export default actions;