<template>
  <component :is="node" @on-event="onEvent" :linkTo="passedLinkTo" />
</template>
<script>
import { mapGetters } from "vuex";
import { elementDataMixin } from "../../mixins/element-data";
import { calculateScore } from "../../store/track/handlers/context-handler";
export default {
  name: "score",
  props: ["threshold","passedLinkTo"],
  mixins: [elementDataMixin],
  computed: {
    ...mapGetters(["trackRecord"]),
    passed: function () {
      return this.trackRecord.data.score >= this.threshold;
    },
    node: function () {
      if (this.passed && this.trackRecord.data.score == 1) return "ace-node";
      else if (this.passed) return "passed-node";
      else return "not-passed-node";
    },
  },
  mounted() {
    this.calculateScore();
  },
  methods: {
    calculateScore() {
      let score = calculateScore();
      this.updateElementDataValue(this.elementData.prop, score, true);
    },
    onEvent(event) {
      this.$emit("on-event", event);
    },
  },
};
</script>