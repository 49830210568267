<template>
  <div class="text-field">
    <legend class="v-label">{{ label }}</legend>
    <v-text-field v-model="currentValue" dense filled></v-text-field>
  </div>
</template>
<script>
import { getField } from "../../store/track/handlers/context-handler";
import { elementDataMixin } from "../../mixins/element-data";
export default {
  name: "text-field",
  mixins: [elementDataMixin],
  computed: {
    label: function () {
      return getField(this.elementData.prop).value;
    },
  },
};
</script>
<style scoped>
.text-field {
  height: auto;
  line-height: 20px;
  letter-spacing: normal;
}
.text-field .v-label {
  font-size: 14px;
  cursor: text;
  color: rgba(0, 0, 0, 0.6);
  height: 20px;
  width: 100%;
  line-height: 20px;
  letter-spacing: normal;
  margin-bottom: 4px;
}
</style>