<template>
  <div class="eixo-container">
    <div class="number" v-if="number">
      #{{ number }}
      <v-img
        contain
        class="arrow"
        style="margin-bottom: -24px"
        :src="require('../../assets/images/seta.svg')"
      ></v-img>
    </div>
    <div class="eixo">{{ label }}</div>
  </div>
</template>
<script>
export default {
  name: "eixo-title",
  props: ["label", "number"],
};
</script>
<style lang="scss" scoped>
@import "../../sass/variables.scss";
.eixo-container {
  font-family: $jelly-font-family;
  line-height: 1;
}
.number {
  font-size: 60px;
  color: var(--v-accent-base);
  display: flex;
  margin-left: -40px;
  margin-bottom: -10px;
}
@media only screen and (max-width: 960px) {
  .number {
    font-size: 40px;
    margin-left: -36px;
    margin-bottom: -4px;
  }
}
.arrow {
  max-width: 60px;
}
@media only screen and (max-width: 960px) {
  .arrow {
    max-width: 40px;
    margin-left: 8px;
  }
}
.eixo {
  color: var(--v-blue-base);
  font-size: 100px;
  text-shadow: 0px 1px var(--v-accent-base), 0px 2px var(--v-accent-base),
    0px 3px var(--v-accent-base), 0px 4px var(--v-accent-base),
    0px 5px var(--v-accent-base), 0px 6px var(--v-accent-base),
    0px 7px var(--v-accent-base), 0px 8px var(--v-accent-base),
    0px 9px var(--v-accent-base), 0px 10px var(--v-accent-base),
    0px 11px var(--v-accent-base), 0px 12px var(--v-accent-base);
}
@media only screen and (max-width: 960px) {
  .eixo {
    font-size: 60px;
  }
}
</style>