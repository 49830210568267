<template>
  <div class="loading-container">
    <v-progress-circular
      :size="70"
      indeterminate
      color="yellow"
    ></v-progress-circular>
  </div>
</template>
<script>
export default {
  name: "loading",
};
</script>
<style lang="scss" scoped>
.loading-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  position: absolute;
  background: rgba(black, 0.4);
}
</style>