const getters = {
    user(state) {
        return state.user;
    },
    loggedIn(state) {
        return state.user.loggedIn
    },
    userData(state) {
        return state.user.data;
    },
    userRoles(state) {
        return state.user.roles;
    },
}
export default getters;
