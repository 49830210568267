<template>
  <span class="nav-btn-container">
    <v-btn v-if="isMobile" @click="drawer = true" fab small color="accent">
      <v-icon color="white">mdi-book-open-page-variant</v-icon>
    </v-btn>
    <v-navigation-drawer
      v-model="drawer"
      app
      :permanent="!isMobile"
      :hide-overlay="!isMobile"
      :temporary="isMobile"
      width="320px"
      ref="nodeNav"
    >
      <v-list-item>
        <v-list-item-title
          class="text-wrap primary--text text-uppercase font-weight-bold"
        >
          Trilha de aprendizagem
        </v-list-item-title>
        <v-btn v-if="isMobile" icon @click.stop="drawer = !drawer">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      <v-list
        v-for="(chapter, chapterIndex) in navigation"
        :key="`cap-${chapterIndex}`"
        dense
      >
        <v-divider></v-divider>
        <v-subheader> {{ chapter.name }}</v-subheader>
        <v-list-item
          :ref="view.id"
          v-for="(view, viewIndex) in chapter.views"
          :key="`view-${viewIndex}`"
          :disabled="(view.disabled && trackStatus != 'closed') || chapter.locked"
          :class="viewID == view.id ? 'grey lighten-2' : ''"
          @click="handleLinkClick(view.id)"
        >
          <v-list-item-icon>
            <v-icon v-if="chapter.locked">mdi-lock-clock</v-icon>
            <v-icon v-else-if="!view.depedencies || view.depedencies.length == 0">
              mdi-circle-small
            </v-icon>
            <v-icon v-else-if="view.valid === true" color="success">
              mdi-check-circle
            </v-icon>
            <v-icon v-else-if="view.valid === false" color="black">
              mdi-circle-outline
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-wrap">
              {{ view.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </span>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "node-navigation",
  data: () => ({ drawer: false, openPanels: [] }),
  computed: {
    ...mapGetters(["navigation", "viewID", "trackStatus"]),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  mounted() {
    this.navDrawerContent = this.$refs["nodeNav"].$el.querySelector(
      "div.v-navigation-drawer__content"
    );
    this.openPanels = Array.from(Array(this.navigation.length).keys());
  },
  watch: {
    viewID: function (val) {
      this.$vuetify.goTo(this.$refs[val][0], {
        container: this.navDrawerContent,
      });
    },
  },
  methods: {
    ...mapActions(["dispatchTrackEvent"]),
    handleLinkClick(viewID) {
      this.dispatchTrackEvent({ type: "node-link", linkTo: viewID });
    },
    alternative(index) {
      return (index + 10).toString(36) + ".";
    },
  },
};
</script>
<style scoped>
.nav-btn-container {
  position: absolute;
  left: 16px;
  top: 16px;
}
</style>
