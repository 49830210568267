<template>
  <div class="validation-container">
    <v-scroll-x-transition mode="out-in">
      <v-btn
        rounded
        elevation="6"
        v-if="!currentValue"
        color="secondary"
        @click="currentValue = !currentValue"
        large
      >
        <v-icon left> mdi-circle-outline</v-icon>
        <span style="text-transform: none; font-weight: 300">
          Marcar aula como finalizada
        </span>
      </v-btn>
      <v-alert
        v-else
        type="success"
        color="secondary"
        text
        dense
        style="margin-bottom: 0"
      >
        Aula finalizada
      </v-alert>
    </v-scroll-x-transition>
  </div>
</template>
<script>
import { elementDataMixin } from "../../mixins/element-data";
export default {
  name: "screen-validation",
  props: ["label", "completedLabel"],
  mixins: [elementDataMixin],
  methods: {
    validate(value) {
      return value;
    },
    updateElementDataValue(prop, value, valid) {
      let event = {
        type: "element-value-update",
        payload: {
          prop: prop,
          value: value,
          valid: valid != null ? valid : true,
        },
      };
      if (value === true) event["sendData"] = true;
      this.$emit("on-event", event);
    },
  },
};
</script>
<style scoped>
.validation-container {
  min-height: 46px;
}
</style>
