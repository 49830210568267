<template>
  <div class="end-trigger-container">
    <v-scroll-x-transition mode="out-in">
      <div
        v-if="trackStatus != 'closed'"
        class="d-flex flex-column align-center"
      >
        <div v-if="!readyToComplete" class="mb-4">
          <div class="mudadores-p mb-4 accent--text">
            Você precisa finalizar as seguintes aulas para concluir a trilha:
          </div>
          <v-card>
            <v-container>
              <v-list
                v-for="chapter in depedencies"
                :key="chapter.id"
                color="transparent"
                outlined
                rounded
              >
                <v-subheader class="accent--text">{{
                  chapter.name
                }}</v-subheader>
                <v-list-item v-for="view in chapter.views" :key="view.id">
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap accent--text">
                      {{ view.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="goToView(view.id)">
                      <v-icon color="secondary">mdi-arrow-right</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-container>
          </v-card>
        </div>
        <button
          @click="onClick"
          class="mudadores-btn mt-6"
          :disabled="!readyToComplete"
        >
          Concluir Trilha
        </button>
      </div>
      <div v-else>
        <v-alert type="success" text> Trilha completa </v-alert>
        <a href="/certificates">Baixe seu certificado aqui</a>
      </div>
    </v-scroll-x-transition>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "end-track-trigger",
  computed: {
    ...mapGetters(["readyToComplete", "trackStatus", "navigation"]),
    depedencies: function () {
      let chapters = [];
      this.navigation.forEach((chapter) => {
        let newChapter = { id: chapter.id, name: chapter.name, views: [] };
        chapter.views.forEach((view) => {
          if (
            view.depedencies &&
            view.depedencies.length > 0 &&
            view.valid == false
          )
            newChapter.views.push(view);
        });
        if (newChapter.views.length > 0) chapters.push(newChapter);
      });
      return chapters;
    },
  },
  methods: {
    goToView(viewID) {
      this.$emit("on-event", { type: "node-link", linkTo: viewID });
    },
    onClick: function () {
      if (!this.readyToComplete) return;
      this.$emit("on-event", { type: "dummy", endTrack: true });
    },
  },
};
</script>
<style scoped>
.end-trigger-container {
  min-height: 40px;
}
</style>