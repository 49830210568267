<template>
  <a class="brand-container" @click="onClick">
    <image-node image="brand.svg"></image-node>
  </a>
</template>
<script>
export default {
  name: "brand",
  methods: {
    onClick: function () {
      if (this.$route.name === "Home") return;
      this.$router.push("/").catch(() => {});
    },
  },
};
</script>
<style scoped>
.brand-container {
  width: 100%;
  max-width: 400px;
}
</style>
