
const mutations = {
    SET_USER_TRACK_RECORD(state, trackRecord) {
        state.track.user.trackRecord = trackRecord;
    },
    SET_TRACK(state, payload) {
        state.track.data = payload.data;
        state.track.views = payload.views;
        state.track.id = payload.id;
    },
    SET_TRACK_DATA(state, data) {
        state.track.data = data;
    },
    SET_TRACK_INFO(state, info) {
        state.track.data.info = info;
    },
    SET_TRACK_CONTEXT(state, context) {
        state.track.data.context = context;
    },
    SET_TRACK_CHAPTERS(state, chapters) {
        state.track.data.chapters = chapters;
    },
    SET_TRACK_VIEW(state, payload) {
        state.track.views[payload.id] = payload.view
    },
    SET_CURRENT_TRACK_VIEW_ID(state, viewID) {
        let foundView = state.track.views[viewID];
        if (!foundView) viewID = this.context.entryPoint;
        state.track.user.trackRecord.previousViewID = state.track.user.trackRecord.currentViewID;
        state.track.user.trackRecord.currentViewID = viewID;
    },
    SET_TRACK_ELEMENT_DATA(state, payload) {
        let newData = { ...state.track.user.trackRecord.data, [payload.prop]: payload.value }
        state.track.user.trackRecord.data = newData;

        let newValidElements = { ...state.track.user.trackRecord.validElements, [payload.prop]: payload.valid }
        state.track.user.trackRecord.validElements = newValidElements
    },
    SET_CURRENT_VIEW_VALID_STATE(state, valid) {
        state.track.state.validView = valid
    },
    SET_TRACK_STATUS(state, status) {
        state.track.user.trackRecord.status = status;
        state.track.user.trackRecord[`${status}At`] = Date.now()
    },
    SET_NAVIGATION(state, navigation) {
        state.track.user.trackRecord.navigation = navigation;
    }
}

export default mutations;