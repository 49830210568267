let Vimeo = require("vimeo").Vimeo;

const clientId = "6aece9240996ad60344cea75d9bf264c3679c108";
const clientSecret =
    "TQpnjy/tbB03dN04hN+mUj+gSY/rNR3WvDK6Rlds3OSyr01KM95lWBsmIoCYd32a4aMhu+BqjheV1NKdCjD0Rc28pFl6Fd++CPmgm3jmfbjhlg1yA1aiDrJjSX6QdmWy";
const accessToken = "86750db76955e077717e9f69b7052ef3";

let client = new Vimeo(clientId, clientSecret, accessToken);

let data = {}

export async function getVideoFile(videoId, successCallback, errorCallback) {
    if (data[videoId]) {
        successCallback(data[videoId])
        return;
    }
    let responseData = {}
    client.request(
        {
            method: "GET",
            path: "/videos/" + videoId
        },
        function (videoError, videoBody) {
            if (videoError) {
                errorCallback(videoError);
            }
            else {
                responseData = handleVideoGetResponse(videoBody)
                client.request(
                    {
                        method: "GET",
                        path: "/videos/" + videoId + "/texttracks"
                    },
                    function (error, subBody) {
                        if (error) {
                            errorCallback(error);
                        } else {
                            responseData["subtitles"] = handleSubBody(subBody)
                            data[videoId] = responseData
                            successCallback(data[videoId]);
                        }
                    }
                );
            }
        }
    );
}

function handleVideoGetResponse(body) {
    let aspectRatio = body.files[0].height / body.files[0].width;

    let files = body.files
        .filter((file) => file.quality != "hls")
        .sort(function (a, b) {
            if (a.height > b.height) {
                return -1;
            }
            if (a.height < b.height) {
                return 1;
            }
            return 0;
        })
    let srcs = files
        .map((file) => {
            return {
                type: "video/mp4",
                label: `${file.height}p`,
                src: file.link,
            };
        });

    let posterFile = body.pictures.sizes.find((file) => {
        return file.width >= 1920;
    });

    return { videoPoster: posterFile.link, videoSrc: srcs, aspectRatio: aspectRatio }
}

function handleSubBody(subBody) {
    return subBody.data.filter(el => !el.name.includes("auto_generated"));
}