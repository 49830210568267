<template>
  <component
    :is="step.is"
    v-bind="step.options"
    :disabled="!viewValidState && step.event.validate"
    class="step"
    @click="onClick"
  >
    <slot></slot>
  </component>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "node-step",
  props: {
    step: Object,
  },
  methods: {
    onClick() {
      this.$emit("on-event", this.step.event);
    },
  },
  computed: {
    ...mapGetters(["viewValidState"]),
  },
};
</script>
<style lang="scss" scoped>
.step {
  cursor: pointer;
}
</style>
