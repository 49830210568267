<template>
  <div class="thumbnail-container">
    <div class="mudadores-title thumbnail-label">
      <v-icon
        large
        :color="isValid ? 'secondary' : 'blue'"
        v-text="isValid ? 'mdi-check-circle-outline' : 'mdi-circle-outline'"
      >
      </v-icon>
      {{ label }}
    </div>
    <div class="thumbnail-box">
      <div class="thumbnail-wrapper">
        <div class="thumbnail">
          <v-img class="thumbnail-content" :src="src" :lazy-src="lazySrc" contain></v-img>
        </div>
      </div>
      <div class="thumbnail-title-box">
        <div :style="{ 'min-height': $vuetify.breakpoint.mobile ? '0' : '160px' }">
          <div class="thumbnail-title">
            {{ title }}
          </div>
          <div class="thumbnail-description">
            {{ description }}
          </div>
        </div>
        <div class="d-flex justify-end">
          <v-btn @click="onClick" text color="success" class="mt-2">
            Acessar <v-icon right large>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "aula-thumbnail",
  props: ["label", "title", "description", "linkTo", "block", "locked", "img"],
  computed: {
    ...mapGetters(["navigation"]),
    isValid: function () {
      if (!this.linkTo) return false;
      if (!this.navigation || this.navigation.length == 0) return false;
      let chapter = this.navigation.find(
        (chapter) => chapter.views.find((view) => view.id === this.linkTo) != null
      );
      if (!chapter) return false;
      let view = chapter.views.find((view) => view.id === this.linkTo);

      if (!view) return false;
      return view.valid;
    },
    src: function () {
      try {
        return require("../../assets/images/" + this.img);
      } catch (error) {
        return require("../../assets/images/placeholder.jpg");
      }
    },
    lazySrc: function () {
      try {
        return require("../../assets/images/lazy/" + this.img);
      } catch (error) {
        return require("../../assets/images/lazy/placeholder.jpg");
      }
    },
  },
  methods: {
    onClick() {
      if (!this.linkTo || this.locked) return;
      this.$emit("on-event", { type: "node-link", linkTo: this.linkTo });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../sass/variables.scss";
.thumbnail-container {
  width: 100%;
  max-width: 800px;
}
.thumbnail-box {
  display: flex;
}
@media only screen and (max-width: 960px) {
  .thumbnail-box {
    display: block;
  }
}
.thumbnail-title-box {
  margin-left: 16px;
}
@media only screen and (max-width: 960px) {
  .thumbnail-title-box {
    margin-left: 0;
  }
}
.thumbnail-wrapper {
  display: inline-block;
  min-width: 200px;
  max-width: 200px;
  width: 100%;
  margin-bottom: 4px;
}
.thumbnail {
  position: relative;
  height: 0;
  width: 100%;
  padding-top: 100%;
  transition: 400ms;
}

.thumbnail.locked {
  background-color: var(--v-accent-base);
}
.thumbnail-label {
  font-size: 42px;
  line-height: 1;
  margin-bottom: 6px;
}
@media only screen and (max-width: 960px) {
  .thumbnail-label {
    font-size: 32px;
  }
}
.thumbnail-content {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.thumbnail-title {
  line-height: 1;
  color: var(--v-accent-base);
  font-size: 24px;
  margin-bottom: 6px;
}
@media only screen and (max-width: 960px) {
  .thumbnail-title {
    font-size: 18px;
  }
}
.thumbnail-description {
  color: var(--v-accent-base);
  font-size: 16px;
}
@media only screen and (max-width: 960px) {
  .thumbnail-description {
    font-size: 14px;
  }
}
</style>
