async function eventHandler(event, commit, getters) {
    await eventHandlerFunctions[event.type](event, commit, getters);
}

const eventHandlerFunctions = {
    "element-value-update": function (event, commit) {
        commit("SET_TRACK_ELEMENT_DATA", event.payload)
    },
    "node-link": function (event, commit, getters) {
        if (!event["validate"] || getters.viewValidState) {
            commit("SET_CURRENT_TRACK_VIEW_ID", event.linkTo);
        }
    },
    "dummy": () => { }
}


export { eventHandler }