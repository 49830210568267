<template>
  <component :is="component.is" v-bind="component.options" @on-event="onEvent"
    >{{ component.content
    }}<node-tree
      v-for="(node, index) in nodes"
      :key="`node-${index}`"
      :component="node.component"
      :nodes="node.nodes"
      @on-event="onEvent"
    />
  </component>
</template>

<script>
export default {
  name: "node-tree",
  props: {
    component: Object,
    nodes: Array,
  },
  methods: {
    onEvent(event) {
      this.$emit("on-event", event);
    },
  },
};
</script>
