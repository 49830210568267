<template>
  <button class="control-icon" @click="click" v-ripple>
    <v-icon :color="color" :size="size">{{ icon }}</v-icon>
  </button>
</template>
<script>
export default {
  name: "control-icon",
  props: ["icon", "color", "size"],
  methods: {
    click: function (e) {
      e.stopPropagation();
      this.$emit("clicked");
    },
  },
};
</script>
<style lang="scss" scoped>
.control-icon {
  background: rgba(black,0.6);
  border: 4px solid white;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  opacity: 0.6;
  transition: 600ms;
  -webkit-box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.7);
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.7);
}
.control-icon:hover {
  opacity: 1;
  transition: 200ms;
}
@media only screen and (max-width: 768px) {
  .control-icon {
    padding: 10px;
  }
}
</style>
