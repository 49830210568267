<template>
  <span>
    {{ formattedScore }}
  </span>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "score-display",
  computed: {
    ...mapGetters(["trackRecord"]),
    score: function () {
      if (this.trackRecord.data.score == null) return -1;
      return this.trackRecord.data.score;
    },
    formattedScore: function () {
      return parseFloat((this.score * 100).toFixed(0)) + "%";
    },
  },
};
</script>