<template>
  <v-snackbar v-model="show" multi-line top light :timeout="10000">
    <div class="mb-2 accent--text">Boas vindas! você parou em:</div>
    <v-container class="grey lighten-3 accent--text">
      <div class="text-caption">{{ chapter }}</div>
      <div class="mudadores-p">{{ name }}</div>
    </v-container>
    <div class="mt-2 mb-4 accent--text">Deseja retomar de onde parou?</div>
    <v-divider class="mb-4"></v-divider>
    <v-row>
      <v-col cols="12" md="8" class="order-last order-md-first">
        <v-btn color="accent" block text @click="hide">
          permanecer nesta tela
          {{ countdown > 0 ? "(" + countdown + ")" : "" }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="4">
        <v-btn class="mb-2" block rounded color="primary" @click="submit">
          retomar
        </v-btn>
      </v-col>
    </v-row>
  </v-snackbar>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "resume-to-last-view",
  data: () => ({
    show: false,
    name: "Nome",
    chapter: "Capítulo",
    linkTo: "",
    countdown: 10,
    currentViewID: null,
  }),
  computed: {
    ...mapGetters(["viewID", "previousViewID", "navigation"]),
  },
  watch: {
    viewID: function (val) {
      if (val != this.currentViewID && this.show === true) this.show = false;
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(["dispatchTrackEvent"]),
    init() {
      this.currentViewID = this.viewID;
      this.show = false;
      if (this.viewID === this.previousViewID) return;
      if (this.previousViewID == null) return;
      this.linkTo = this.previousViewID;
      let chapter = this.navigation.find(
        (chapter) => chapter.views.find((view) => view.id === this.previousViewID) != null
      );
      if (chapter) {
        this.chapter = chapter.name;
        let view = chapter.views.find((view) => view.id === this.previousViewID);
        if (view) this.name = view.name;
      }
      this.show = true;
      this.countdown = 10;
      var interval = setInterval(() => {
        this.countdown--;
        if (this.countdown == 0) clearInterval(interval);
      }, 1000);
    },
    submit() {
      this.dispatchTrackEvent({
        type: "node-link",
        linkTo: this.linkTo,
      });
      this.hide();
    },
    hide() {
      this.show = false;
    },
  },
};
</script>
