const mutations = {
    SET_LOGGED_IN(state, value) {
        state.user.loggedIn = value;
    },
    SET_USER_DATA(state, value) {
        state.user.data = value;
    },
    SET_USER_ROLE(state, roles) {
        state.user.roles = roles;
    },
}
export default mutations;