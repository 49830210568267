import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"
import "firebase/analytics"

const firebaseConfig = {
  apiKey: "AIzaSyBGL515BgYG4rgQJNiJcYTMLVT9XC1JrYo",
  authDomain: "escolademudadores.app",
  projectId: "escolademudadores",
  storageBucket: "escolademudadores.appspot.com",
  messagingSenderId: "329306003776",
  appId: "1:329306003776:web:e2d43bdce1a37a50e102a5",
  measurementId: "G-VRB9YPMCCY"
};

firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

const auth = firebase.auth();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
auth.languageCode = 'pt-BR'

const db = firebase.firestore();
db.settings({
  experimentalForceLongPolling: true,
  merge: true
});

const storage = firebase.storage().ref();

export { auth, googleAuthProvider, db, storage, analytics };
