<template>
  <div
    v-if="state"
    v-ripple
    class="video-overlay"
    :class="{ gradient: state && !state.playing }"
    :style="videoOverlayStyle"
    @click="onEvent('play')"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <v-container fill-height fluid>
      <v-row justify="center" align="center">
        <v-col class="shrink" v-show="state && state.started">
          <v-fab-transition leave-absolute>
            <control-icon
              icon="mdi-rotate-left"
              :size="$vuetify.breakpoint.mobile ? 32 : 52"
              color="white"
              @clicked="onEvent('rewind')"
            />
          </v-fab-transition>
        </v-col>
        <v-col class="shrink" v-show="state && !state.ended">
          <v-fab-transition leave-absolute>
            <control-icon
              :icon="state.playing ? 'mdi-pause' : 'mdi-play'"
              :size="$vuetify.breakpoint.mobile ? 42 : 72"
              color="white"
              @clicked="onEvent('play')"
            />
          </v-fab-transition>
        </v-col>

        <v-col class="shrink" v-show="state && state.started && !state.ended">
          <v-fab-transition leave-absolute>
            <control-icon
              icon="mdi-rewind-10"
              :size="$vuetify.breakpoint.mobile ? 32 : 52"
              color="white"
              @clicked="onEvent('rewind10')"
            />
          </v-fab-transition>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ControlIcon from "../midia/ControlIcon";
export default {
  props: ["state"],
  components: { ControlIcon },
  data: () => ({ hover: false }),
  computed: {
    showOverlayControl: function () {
      return !this.state.playing || this.hover;
    },
    videoOverlayStyle: function () {
      let opacity = 0;
      if (this.state.playing) {
        if (this.hover) opacity = 0.9;
        else opacity = 0;
      } else {
        if (this.hover) opacity = 1;
        else opacity = 0.9;
      }
      return { opacity: opacity };
    },
  },
  methods: {
    onEvent(event) {
      this.$emit("on-event", event);
    },
  },
};
</script>
<style scoped>
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  transition: opacity 200ms;
}
.gradient {
  background: linear-gradient(
    0deg,
    rgba(2, 0, 36, 0.4) 0%,
    rgba(161, 161, 161, 0.4) 100%
  );
}
</style>