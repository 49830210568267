<template>
  <div class="mudadores-download">
    <a
      class="download-link"
      :href="downloadPath"
      :download="fileName"
      target="_blank"
    >
      <v-btn
        class="download-btn"
        :color="variant == 'student' ? 'yellow' : 'blue'"
        large
        elevation="0"
      >
        <v-icon
          :color="variant == 'student' ? 'blue' : 'yellow'"
          :size="$vuetify.breakpoint.mobile ? 32 : 42"
        >
          mdi-tray-arrow-down
        </v-icon>
      </v-btn>
    </a>
    <span class="label"> {{ label }}</span>
  </div>
</template>
<script>
export default {
  name: "mudadores-download",
  props: ["label", "variant", "file", "fileName"],
  data: () => ({}),
  computed: {
    downloadPath: function () {
      return `/${this.file}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../sass/variables.scss";
.mudadores-download {
  display: flex;
  align-items: center;
}
.mudadores-download .download-link {
  text-decoration: none;
}
.mudadores-download .label {
  font-family: $jelly-font-family;
  font-size: 28px;
  color: var(--v-accent-base);
  margin-left: 8px;
  line-height: 1;
}

@media only screen and (max-width: 960px) {
  .mudadores-download .label {
    font-size: 20px;
  }
}
.mudadores-download .download-btn {
  padding: 0;
  padding-top: 4px;
  min-width: 38px;
  height: 36px;
}

@media only screen and (max-width: 960px) {
  .mudadores-download .download-btn {
    padding-top: 4px;
    min-width: 36px;
    height: 34px;
  }
}
</style>