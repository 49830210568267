<template>
  <v-card>
    <v-container>
      <div class="d-flex flex-column align-center">
        <certificate
          ref="document"
          class="document-container"
          @loaded="loaded = true"
        />
        <v-btn
          class="primary mt-4"
          large
          rounded
          @click="exportToPDF"
          :loading="!loaded"
        >
          <v-icon left>mdi-download</v-icon>
          {{ loaded ? "Baixar" : "Carregando..." }}
        </v-btn>
      </div>
    </v-container>
  </v-card>
</template>
<script>
import html2pdf from "html2pdf.js";
export default {
  name: "certificate-download",
  data: () => ({
    loaded: false,
  }),
  computed: {
    options: function () {
      return {
        margin: 0,
        filename: "certificado.pdf",
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          dpi: 192,
          scale: 4,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
      };
    },
  },
  mounted() {
    this.loaded = false;
  },
  methods: {
    exportToPDF() {
      if (!this.loaded) return;
      this.$emit("on-event", {
        type: "element-value-update",
        sendData: true,
        payload: {
          prop: "downloaded_certificate",
          value: true,
          valid: true,
        },
      });
      html2pdf()
        .from(this.$refs.document.$el.innerHTML)
        .set(this.options)
        .save();
    },
  },
};
</script>
<style scoped>
/* .document-container {
  display: none;
} */
</style>