<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <omni-btn> Ver respostas </omni-btn>
      </span>
    </template>

    <v-card>
      <div v-for="(questionKey, index) in questionKeys" :key="questionKey">
        <v-container>
          <div class="text-caption text-uppercase font-weight-bold">
            {{ `Pergunta ${index + 1}` }}
          </div>
          <div class="mb-4">
            {{ mapField(questionKey) }}
          </div>
          <div>
            <div class="text-caption text-uppercase font-weight-bold">
              Você respondeu
            </div>
            <v-sheet outlined rounded>
              <div
                v-for="userAnswerKey in userAnswerKeys(questionKey)"
                :key="`userAnswer${userAnswerKey}`"
                class="pa-2"
              >
                <v-icon
                  v-if="isCorrectAnswer(userAnswerKey, questionKey)"
                  color="success"
                  >mdi-check-decagram</v-icon
                >
                <v-icon v-else color="error">mdi-alert-decagram</v-icon>
                {{ mapField(userAnswerKey) }}
              </div>
            </v-sheet>
            <v-btn
              small
              text
              block
              color="primary"
              class="my-1"
              @click="$set(showAnswer, index, !showAnswer[index])"
              ><v-icon
                small
                class="mr-2"
                v-text="showAnswer[index] ? 'mdi-eye-off' : 'mdi-eye'"
              ></v-icon>
              Ver resposta correta
            </v-btn>
            <v-expand-transition>
              <v-sheet outlined rounded v-if="showAnswer[index]">
                <div
                  v-for="answerKey in answerKeys(questionKey)"
                  :key="`answer${answerKey}`"
                  class="pa-2"
                >
                  <v-icon>mdi-circle-medium</v-icon>
                  {{ mapField(answerKey) }}
                </div>
              </v-sheet>
            </v-expand-transition>
          </div>
        </v-container>
        <v-divider></v-divider>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  getField,
  getAnswer,
} from "../../store/track/handlers/context-handler";
import { mapGetters } from "vuex";
export default {
  name: "answer-map-display",
  data: () => ({
    dialog: false,
    showAnswer: [],
  }),
  computed: {
    ...mapGetters(["context", "trackRecord"]),
    questionKeys: function () {
      return Object.keys(this.context.answerMap);
    },
  },
  methods: {
    mapField(key) {
      return getField(key).value;
    },
    answerKeys: function (key) {
      return getAnswer(key);
    },
    userAnswerKeys: function (key) {
      return this.trackRecord.data[key];
    },
    isCorrectAnswer: function (answerKey, questionKey) {
      return this.context.answerMap[questionKey].indexOf(answerKey) > -1;
    },
  },
};
</script>