const state = {
    track: {
        id: null,
        data: {
            info: null,
            context: null,
            chapters: null
        },
        views: {},
        state: {
            validView: false,
        },
        user: {
            trackRecord: {
                status: null,
                currentViewID: null,
                previousViewID:null,
                validElements: {},
                data: {},
                navigation: {}
            }
        },
    },
}
export default state;