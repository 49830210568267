import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { auth } from "./plugins/firebase";
import "./plugins/global-components"

import moment from "moment";
moment.locale("pt-br")

Vue.config.productionTip = false;
Vue.config.devtools = false;

let app;

auth.onAuthStateChanged(initializeApp);

async function initializeApp(user) {
  await store.dispatch("fetchUserData", user);
  if (!app) {
    app = new Vue({
      vuetify,
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  }
}