import store from "../../index"

export { getField, getAnswer, calculateScore }

function getField(key) {
    if (!store.getters.context) return {}
    let fieldMap = store.getters.context.fieldMap;
    return fieldMap.find(fieldMap => fieldMap.key == key)
}

function getAnswer(key) {
    let answerMap = store.getters.context.answerMap;
    return answerMap[key]
}

function calculateScore() {
    if (!store.getters.context.answerMap || store.getters.context.answerMap.length == 0) return;

    let questionMap = Object.keys(store.getters.context.answerMap)
    let totalCount = 0;
    let correctCount = 0;

    questionMap.forEach((question) => {
        let correctAnswerGroup = getAnswer(question);
        let userAnswers = store.getters.trackRecord.data[question];
        if (userAnswers) {
            userAnswers.forEach((userAnswer) => {
                if (correctAnswerGroup.find((correctAnswer) => correctAnswer == userAnswer)) correctCount++;
                totalCount++;
            });
        }
    });

    return correctCount / totalCount;
}