function updateNavigation(commit, getters) {
    let navigation = []
    Object.keys(getters.chapters)
        .sort((a, b) => {
            if (getters.chapters[a].order > getters.chapters[b].order) return 1;
            else if (getters.chapters[a].order < getters.chapters[b].order) return -1;
            return 0;
        }).forEach((chapterKey) => {
            let chapterItem = getters.chapters[chapterKey]

            let chapter = { name: chapterItem.name, views: [], locked: chapterItem.locked === true }

            chapterItem.views.forEach((viewItem) => {
                let view = viewItem

                view["valid"] = false;

                if (view["depedencies"]) {
                    let completedDepedencies = true;
                    view["depedencies"].forEach(prop => {
                        let validProp = false;
                        if (getters.trackRecord.validElements[prop] === true) validProp = true
                        if (!validProp) completedDepedencies = false;
                    });
                    view["valid"] = completedDepedencies;
                }

                chapter.views.push(view);
            });
            navigation.push(chapter);
        });
    commit("SET_NAVIGATION", navigation)
}

export function validateTrackState(state, commit, getters) {

    updateNavigation(commit, getters);

    let isValid = getters.navigation.find(chapter => chapter.views.find(view => view.id === getters.viewID && view.valid === true) != null) != null

    commit("SET_CURRENT_VIEW_VALID_STATE", isValid)

    return isValid;
}