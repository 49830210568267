import Vue from "vue";
import Vuex from "vuex";

import userState from './user/state'
import userGetters from './user/getters'
import userMutations from './user/mutations'
import userActions from './user/actions'

import trackState from './track/state'
import trackGetters from './track/getters'
import trackMutations from './track/mutations'
import trackActions from './track/actions'

Vue.use(Vuex);

let store = new Vuex.Store({
  state: {
    ...userState,
    ...trackState
  },
  getters: {
    ...userGetters,
    ...trackGetters
  },
  mutations: {
    ...userMutations,
    ...trackMutations
  },
  actions: {
    ...userActions,
    ...trackActions
  },
});

export default store