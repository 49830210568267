import { fetchUserData, getUserRoles, updateProfile } from "../../data/user-data"

const actions = {
    async fetchUserData({ commit }, user) {
        if (user) {
            const userData = await fetchUserData(user.uid)
            commit("SET_USER_DATA", {
                ...userData,
                uid: user.uid,
                email: user.email
            });
            const roles = await getUserRoles();
            commit("SET_USER_ROLE", roles)
        }
        else {
            commit("SET_USER_DATA", null);
            commit("SET_USER_ROLE", null)
        }
        commit("SET_LOGGED_IN", user !== null);
    },
    async updateProfile({ commit, getters }, payload) {
        await updateProfile(
            payload.form,
            async () => {
                const userData = await fetchUserData(getters.user.data.uid)
                commit("SET_USER_DATA", {
                    uid: getters.user.data.uid,
                    ...userData
                });
                payload.successCallback();
            },
            payload.errorCallback);
    },
}
export default actions;