import Vue from "vue";

import VueMask from 'v-mask'
Vue.use(VueMask);

import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

import "video.js/dist/video-js.css";
import '@silvermine/videojs-quality-selector/dist/css/quality-selector.css'
import 'jsoneditor/dist/jsoneditor.min.css'

const requireContext = require.context("../components/global", true, /\.vue$/);
requireContext.keys().forEach((key) => {
    let name = requireContext(key).default.name
    let component = import("../components/global" + key.replace("./", "/"));
    Vue.component(name, () => component);
});

import { VBtn, VRow, VCol, VContainer, VCarousel, VCarouselItem, VSheet, VIcon, VDivider, VSpacer,VCard } from 'vuetify/lib'
Vue.component('v-btn', VBtn);
Vue.component('v-row', VRow);
Vue.component('v-col', VCol);
Vue.component('v-container', VContainer);
Vue.component('v-carousel', VCarousel);
Vue.component('v-carousel-item', VCarouselItem);
Vue.component('v-sheet', VSheet);
Vue.component('v-icon', VIcon);
Vue.component('v-divider', VDivider);
Vue.component('v-spacer', VSpacer);
Vue.component('v-card', VCard);
