<template>
  <div class="document-wrapper">
    <div class="document">
      <img
        class="image-bg"
        :src="require('../../assets/images/certificado.jpg')"
        @load="onLoad"
      />
      <div class="name">
        {{ userData.name }}
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "certificate",
  computed: {
    ...mapGetters(["userData", "trackRecord"]),
    certificateDate: function () {
      let closedAt = this.trackRecord ? this.trackRecord.closedAt : "2022";

      moment.locale("pt");

      return this.userData.city + ", " + moment(closedAt).format("LL");
    },
  },
  methods: {
    onLoad() {
      this.$emit("loaded");
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../sass/variables.scss";
.document {
  position: relative;
  height: 210mm;
  width: 297mm;
  color: black;
}
.image-bg {
  position: absolute;
  height: 210mm;
  width: 297mm;
  top: 0;
  left: 0;
}
.name {
  position: absolute;
  color: var(--v-accent-base);
  bottom: 43%;
  width: 100%;
  line-height: 1;
  padding: 0 12%;
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  font-size: 40px;
  font-family: $body-font-family;
}
</style>