<template>
  <div class="bg"></div>
</template>
<script>
export default {
  name: "background",
  data: () => ({}),
};
</script>
<style lang="scss" scoped>
.bg {
  z-index: -1;
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
