<template>
  <div>
    <v-row justify="center">
      <v-btn-toggle>
        <v-btn
          v-for="(marker, index) in markers"
          :key="`markers-${index}`"
          :color="completed(index) ? 'primary' : 'white'"
          outlined
          @click="updateProgress(marker.time)"
        >
          {{ `${formatTime(marker.time)} - ${marker.text}` }}
        </v-btn>
      </v-btn-toggle>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "video-marker-control",
  props: ["videoProp"],
  computed: {
    ...mapGetters(["trackRecord", "context"]),
    markers: function () {
      let markers = this.context.videoMarkers[`${this.videoProp}_markers`];
      return markers;
    },
    currentTime: function () {
      return this.trackRecord.data[`${this.videoProp}_currentTime`];
    },
    ended: function () {
      return this.trackRecord.data[`${this.videoProp}_reachedEnd`];
    },
    currentMarkerIndex: function () {
      let currentMarkerIndex = null;
      this.markers.forEach((marker, index) => {
        if (marker.time <= this.currentTime) currentMarkerIndex = index;
      });
      return currentMarkerIndex;
    },
    currentMarker: function () {
      return this.markers[this.currentMarkerIndex];
    },
  },
  methods: {
    updateProgress(value) {
      this.$emit("on-event", {
        type: "element-value-update",
        payload: {
          prop: `${this.videoProp}_currentTime`,
          value: value,
          valid: true,
        },
      });
    },
    completed(markerIndex) {
      if (this.markers[markerIndex].time < this.currentTime) return true;
      return false;
    },
    formatTime(time) {
      return moment(time * 1000).format("mm:ss");
    },
  },
};
</script>
