<template>
  <v-img
    class="watermark"
    :src="require('../../assets/images/brand.svg')"
    contain
    max-width="60px"
  ></v-img>
</template>
<script>
export default {
  name: "watermark",
};
</script>
<style scoped>
.watermark {
  position: absolute;
  right: 30px;
  top: 20px;
}
</style>