<template>
  <v-radio-group v-model="currentValue" :label="label" dense row required>
    <v-radio
      v-for="item in elementData.items"
      :key="`radio_${item.prop}_${item.value}`"
      :label="item.label"
      :value="item.value"
    ></v-radio>
  </v-radio-group>
</template>
<script>
import { getField } from "../../store/track/handlers/context-handler";
import { elementDataMixin } from "../../mixins/element-data";
export default {
  name: "radio-field",
  mixins: [elementDataMixin],
  computed: {
    label: function () {
      return getField(this.elementData.prop).value;
    },
  },
};
</script>
<style>
.v-input--radio-group__input legend {
  width: 100%;
  margin-bottom: 4px;
}
</style>