import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "../store"

Vue.use(VueRouter)

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    },
  },
  {
    path: "/auth",
    name: "Auth",
    component: () => import('../views/Auth'),
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: "/cadastro",
    name: "Profile",
    component: () => import('../views/Profile'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/finish-profile",
    name: "RequestToCompleteProfile",
    component: () => import('../views/RequestToCompleteProfile'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/certificates",
    name: "Certificates",
    component: () => import('../views/Certificates'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: () => import('../views/FAQ'),
  },
  {
    path: "/editor/:trackID",
    name: "Editor",
    component: () => import('../views/Editor'),
    props: true,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    },
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const hideForAuth = to.matched.some((record) => record.meta.hideForAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
  const loggedIn = store.getters.user.loggedIn;

  if (loggedIn === false && requiresAuth === true) {
    return next({ path: "/auth" });
  }
  if (loggedIn === true && store.getters.user.data.hasCompleteProfile === false && to.path != "/finish-profile") {
    return next({ path: "/finish-profile" });
  }
  if (hideForAuth === true && loggedIn === true) {
    return next({ path: "/" });
  }
  if (requiresAdmin === true && store.getters.user.roles.admin.active===false) {
    return next({ path: "/" });
  }
  return next();
});

export default router