<template>
  <v-img :lazy-src="lazySrc" :src="src" contain>
    <template v-slot:placeholder>
      <v-row class="fill-height ma-0" align="center" justify="center">
        <v-progress-circular indeterminate color="yellow"></v-progress-circular>
      </v-row>
    </template>
  </v-img>
</template>
<script>
export default {
  name: "image-node",
  props: ["image", "mobile"],
  computed: {
    src: function () {
      var source = null;
      if (this.$vuetify.breakpoint.mobile && this.mobile) {
        source = require("../../assets/images/" + this.mobile);
      } else {
        source = require("../../assets/images/" + this.image);
      }
      return source;
    },
    lazySrc: function () {
      try {
        return require("../../assets/images/lazy/" + this.image);
      } catch (error) {
        return "";
      }
    },
  },
};
</script>