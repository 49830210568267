<template>
  <component :is="containerElement.is" v-bind="containerElement.options">
    <component
      :is="questionWrapperElement.is"
      v-bind="questionWrapperElement.options"
    >
      <component :is="questionElement.is" v-bind="questionElement.options">
        {{ questionField.value }}
      </component>
    </component>
    <component
      :is="answerContainerElement.is"
      v-bind="answerContainerElement.options"
    >
      <component
        v-for="(choiceField, index) in choiceFields"
        :key="`answer-${index}`"
        :is="answerWrapperElement.is"
        v-bind="answerWrapperElement.options"
      >
        <component
          :is="
            isSelected(choiceField.key)
              ? answerElement.is
              : acviteAnswerElement.is
          "
          v-bind="
            isSelected(choiceField.key)
              ? acviteAnswerElement.options
              : answerElement.options
          "
          :disabled="trackStatus == 'closed'"
          @click="select(choiceField.key)"
        >
          <component
            :is="answerIndexElement.is"
            v-bind="answerIndexElement.options"
          >
            <span class="text-uppercase font-weight-bold"
              >{{ alternative(index) }}.</span
            >
          </component>
          {{ choiceField.value }}
        </component>
      </component>
    </component>
  </component>
</template>
<script>
import { getField } from "../../store/track/handlers/context-handler";
import { elementDataMixin } from "../../mixins/element-data";

export default {
  name: "multiple-choice",
  mixins: [elementDataMixin],
  props: {
    choices: { type: Array },
    containerElement: {
      type: Object,
      default: () => ({ is: "v-container", options: { "pa-0": true } }),
    },
    questionWrapperElement: {
      type: Object,
      default: () => ({ is: "v-container", options: { "pa-0": true } }),
    },
    questionElement: {
      type: Object,
      default: () => ({ is: "div", options: { class: "question-title" } }),
    },
    answerContainerElement: {
      type: Object,
      default: () => ({ is: "v-row", options: { justify: "center",class:"mt-2" } }),
    },
    answerWrapperElement: {
      type: Object,
      default: () => ({ is: "v-col", options: { cols: "12" } }),
    },
    answerElement: {
      type: Object,
      default: () => ({
        is: "button",
        options: {
          class: "choice-btn",
        },
      }),
    },
    acviteAnswerElement: {
      type: Object,
      default: () => ({
        is: "button",
        options: {
          class: "choice-btn active",
        },
      }),
    },
    answerIndexElement: {
      type: Object,
      default: () => ({ is: "span" }),
    },
  },
  methods: {
    select(id) {
      let newValue = this.currentValue;
      if (newValue == null) newValue = [];

      let selectedIndex = newValue.indexOf(id);
      if (selectedIndex < 0) newValue.push(id);
      else newValue.splice(selectedIndex, 1);

      this.updateElementDataValue(
        this.elementData.prop,
        newValue,
        newValue.length > 0
      );
    },
    isSelected(id) {
      if (!this.currentValue) return false;
      return this.currentValue.find((el) => el == id) != null;
    },
    alternative(index) {
      return (index + 10).toString(36);
    },
  },
  computed: {
    questionField: function () {
      if (!this.elementData || !this.elementData.prop)
        return { key: "null", value: "Missing question" };
      return getField(this.elementData.prop);
    },
    choiceFields: function () {
      if (!this.choices) return [{ key: "null", value: "Missing answer" }];
      return this.choices.map((id) => getField(id));
    },
  },
};
</script>
<style scoped>
.question-title {
  font-size: 32px;
  font-weight: bold;
}

.choice-btn {
  cursor: pointer;
  width: 100%;
  border-radius: 26px;
  font-size: 20px;
  text-align: left;
  padding: 10px 60px;
  transition: all 200ms;
  background: var(--v-orange-base);
  color: white;
}

@media only screen and (max-width: 1335px) {
  .question-title {
    font-size: 22px;
  }
  .choice-btn {
    font-size: 16px;
  }
}

.choice-btn:hover {
  background: var(--v-orange-lighten1);
}

.choice-btn.active {
  background: var(--v-primary-base);
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
}
</style>