<template>
  <div
    class="video-container"
    :key="id"
    :style="{ paddingTop: aspectRatioPadding }"
    @mouseenter="onHover(true)"
    @mouseleave="onHover(false)"
  >
    <div class="video" v-show="!isLoading">
      <video ref="videoPlayer" class="video-js vjs-default-skin"></video>
    </div>
    <loading v-if="isLoading" />
    <video-overlay v-else :state="state" @on-event="onControlEvent" />
  </div>
</template>
<script>
import VideoOverlay from "../midia/VideoOverlay";
import videojs from "video.js";
import "videojs-markers";
import { getVideoFile } from "../../plugins/vimeo";
import { mapGetters } from "vuex";
require("@silvermine/videojs-quality-selector")(videojs);

export default {
  name: "video-player",
  components: { VideoOverlay },
  props: {
    id: String,
  },
  data() {
    return {
      player: null,
      isLoading: false,
      hasError: false,
      showOverlayControl: false,
      started: false,
      ended: false,
      playing: false,
      videoSrc: [],
      videoPoster: null,
      aspectRatioPadding: "56.25%",
      subtitles: [],
    };
  },
  computed: {
    ...mapGetters(["trackID", "trackRecord", "context"]),
    prop: function () {
      return `video_${this.id}`;
    },
    state: function () {
      return {
        started: this.started,
        ended: this.ended,
        playing: this.playing,
        currentTime: this.currentTime,
      };
    },
    playerOptions: function () {
      return {
        // inactivityTimeout: 0,
        autoplay: false,
        controls: true,
        fluid: true,
        bigPlayButton: false,
        poster: this.videoPoster,
        sources: this.videoSrc,
        controlBar: {
          children: [
            "playToggle",
            "volumePanel",
            "durationDisplay",
            "timeDivider",
            "currentTimeDisplay",
            "progressControl",
            "qualitySelector",
            "fullscreenToggle",
          ],
        },
      };
    },
  },
  mounted() {
    this.loadData();
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    loadData() {
      this.isLoading = true;
      this.hasError = false;
      this.getStorageVideo();
    },
    getStorageVideo() {
      getVideoFile(
        this.id,
        (data) => {
          this.subtitles = data.subtitles;
          this.aspectRatioPadding = (data.aspectRatio * 100).toFixed(2) + "%";
          this.videoSrc = data.videoSrc;
          this.videoPoster = data.videoPoster;
          this.mountPlayer();
        },
        (error) => {
          console.log(error);
          this.hasError = true;
        }
      );
    },
    onControlEvent(event) {
      switch (event) {
        case "play":
          this.togglePause();
          break;
        case "rewind":
          this.rewind();
          break;
        case "rewind10":
          this.rewind10();
          break;
      }
    },
    mountPlayer() {
      this.player = videojs(
        this.$refs.videoPlayer,
        this.playerOptions,
        this.onPlayerReady
      );
    },
    onPlayerReady() {
      this.player.on("play", this.onPlay);
      this.player.on("pause", this.onPause);
      this.player.on("ended", this.onEnd);

      this.subtitles.forEach((sub, i) => {
        let track = {
          kind: sub.type,
          label: "Português",
          language: "pt-BR",
          id: "track" + i,
          src: sub.link,
          default: "true",
        };

        this.player.addRemoteTextTrack(track, true);
      });

      setTimeout(() => {
        this.isLoading = false;
      }, 400);
    },
    togglePause() {
      if (!this.playing) this.player.play();
      else this.player.pause();
    },
    rewind() {
      this.player.currentTime(0);
      this.player.play();
    },
    onEnd() {
      this.ended = true;
      this.playing = false;
      this.player.posterImage.show();
    },
    onPlay() {
      this.ended = false;
      this.playing = true;
      this.started = true;
    },
    onPause() {
      this.playing = false;
    },
    onHover(value) {
      if (!this.player) return;
      this.player.userActive(value);
    },
    rewind10() {
      let time = this.player.currentTime() - 10;
      if (time < 0) {
        time = 0;
      }
      this.player.currentTime(time);
    },
  },
};
</script>
<style lang="scss" >
@import "../../sass/variables.scss";
.video-container {
  width: 100%;
  height: 0px;
  position: relative;
  background-color: grey;
  -webkit-box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.7);
  box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.7);
}
.video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video-js {
  color: white;
  font-size: 12px;
}
@media only screen and (max-width: 768px) {
  .video-js {
    font-size: 10px;
  }
}

.vjs-tech:focus {
  outline: none !important;
}

.vjs-control-bar {
  z-index: 2;
  transition: visibility 200ms, opacity 200ms;
}
.video-js .vjs-play-progress:before {
  font-size: 0.9em;
  position: absolute;
  right: -0.5em;
  top: -0.3em;
  z-index: 1;
}
.video-js .vjs-control-bar,
.video-js .vjs-big-play-button,
.video-js .vjs-menu-button .vjs-menu-content {
  background-color: var(--v-primary-base);
}

.video-js .vjs-slider {
  background-color: var(--v-primary-lighten1);
}

.video-js .vjs-slider-bar {
  background: var(--v-primary-lighten1);
}
.video-js .vjs-progress-control:hover .vjs-progress-holder {
  font-size: 3em;
}
.video-js .vjs-volume-level,
.video-js .vjs-play-progress,
.videos-js .vjs-quality-selector {
  background: var(--v-secondary-lighten2);
}
.vjs-menu li.vjs-selected,
.vjs-menu li.vjs-selected:focus,
.vjs-menu li.vjs-selected:hover,
.js-focus-visible .vjs-menu li.vjs-selected:hover {
  background-color: var(--v-primary-darken2);
  color: white;
}
.vjs-menu li.vjs-menu-item:focus,
.vjs-menu li.vjs-menu-item:hover,
.js-focus-visible .vjs-menu li.vjs-menu-item:hover {
  background-color: var(--v-primary-lighten2);
}

.video-js .vjs-load-progress div {
  background: var(--v-primary-lighten2);
}
.video-js .vjs-control:focus:before,
.video-js .vjs-control:hover:before,
.video-js .vjs-control:focus {
  text-shadow: none;
}

.video-js .vjs-time-tooltip,
.video-js .vjs-progress-control .vjs-mouse-display .vjs-time-tooltip,
.video-js .vjs-volume-control .vjs-volume-tooltip {
  background-color: var(--v-accent-base);
  font-size: 10px !important;
  color: white;
}

.video-js .vjs-progress-control .vjs-mouse-display {
  position: absolute;
  width: 4px;
  height: 100%;
  background-color: var(--v-secondary-base);
  z-index: 2;
}
.video-js .vjs-play-progress:before {
  position: absolute;
  background-color: white;
  right: -6px;
  top: 0;
  height: 100%;
  width: 12px;
  z-index: 1;
}
.video-js .vjs-play-progress:before {
  content: "";
}
.video-js .vjs-progress-control:hover .vjs-time-tooltip {
  font-size: 0.2em;
}
.vjs-marker {
  position: absolute;
  left: 0;
  bottom: 0em;
  opacity: 0.7;
  height: 100%;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  z-index: 2;
}
.vjs-marker:hover {
  cursor: pointer;
  opacity: 1;
  -webkit-transform: scale(2);
  -moz-transform: scale(2);
  -o-transform: scale(2);
  -ms-transform: scale(2);
  transform: scale(2);
}
.vjs-tip .vjs-tip-arrow {
  display: none;
}
.vjs-tip {
  visibility: hidden;
  display: block;
  opacity: 1;
  padding: 12px;
  font-size: 1.2rem;
  position: absolute;
  bottom: 3rem;
  z-index: 100000;
}
.vjs-tip .vjs-tip-arrow {
  background: url(data:image/gif;base64,R0lGODlhCQAJAIABAAAAAAAAACH5BAEAAAEALAAAAAAJAAkAAAIRjAOnwIrcDJxvwkplPtchVQAAOw==)
    no-repeat top left;
  bottom: 0;
  left: 50%;
  margin-left: -4px;
  background-position: bottom left;
  position: absolute;
  width: 9px;
  height: 5px;
}
.vjs-tip .vjs-tip-inner {
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  padding: 5px 8px 4px 8px;
  background-color: var(--v-primary-base);
  color: white;
  font-size: 16px;
  max-width: 200px;
  text-align: center;
}
.vjs-break-overlay {
  visibility: hidden;
  position: absolute;
  z-index: 100000;
  top: 0;
}
.vjs-break-overlay .vjs-break-overlay-text {
  padding: 9px;
  text-align: center;
}
.video-js .vjs-text-track-cue div {
  background: transparent !important;
  font-weight: bold !important;
  color: var(--v-accent-base) !important;
  text-shadow: 2px 2px 0 var(--v-yellow-base), 2px -2px 0 var(--v-yellow-base),
    -2px 2px 0 var(--v-yellow-base), -2px -2px 0 var(--v-yellow-base),
    2px 0px 0 var(--v-yellow-base), 0px 2px 0 var(--v-yellow-base),
    -2px 0px 0 var(--v-yellow-base), 0px -2px 0 var(--v-yellow-base),
    3px 3px 0 var(--v-yellow-base), 3px -3px 0 var(--v-yellow-base),
    -3px 3px 0 var(--v-yellow-base), -3px -3px 0 var(--v-yellow-base),
    3px 0px 0 var(--v-yellow-base), 0px 3px 0 var(--v-yellow-base),
    -3px 0px 0 var(--v-yellow-base), 0px -3px 0 var(--v-yellow-base),
    4px 4px 0 var(--v-yellow-base), 4px -4px 0 var(--v-yellow-base),
    -4px 4px 0 var(--v-yellow-base), -4px -4px 0 var(--v-yellow-base),
    4px 0px 0 var(--v-yellow-base), 0px 4px 0 var(--v-yellow-base),
    -4px 0px 0 var(--v-yellow-base), 0px -4px 0 var(--v-yellow-base);
  font-family: $body-font-family !important;
}
</style>
