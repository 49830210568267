<template>
  <div class="video-container enter-animation-target">
    <iframe
      ref="iframe"
      :src="src"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
    ></iframe>
  </div>
</template>
<script>
import Player from "@vimeo/player";
import { mapGetters } from "vuex";
export default {
  name: "vimeo-embed",
  props: ["id"],
  data: () => ({ ended: false }),
  computed: {
    ...mapGetters(["trackID", "trackRecord"]),
    src: function () {
      return `https://player.vimeo.com/video/${this.id}?h=9a8a7ec8af&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`;
    },
    prop: function () {
      return `video_${this.id}`;
    },
  },
  mounted() {
    if (this.trackID && !this.trackRecord.data[this.prop])
      this.updateValue(this.prop, false, false);

    const iframe = this.$refs["iframe"];
    const player = new Player(iframe);

    player.on("ended", this.onEnd);
  },
  methods: {
    onEnd() {
      this.ended = true;
      this.updateValue(this.prop, true, true);
    },
    updateValue(prop, value, valid) {
      this.$emit("on-event", {
        type: "element-value-update",
        payload: {
          prop: prop,
          value: value,
          valid: valid != null ? valid : true,
        },
      });
    },
  },
};
</script>
<style scoped>
.video-container {
  padding: 56.25% 0 0 0;
  position: relative;
  background-color: var(--v-dark-base);
  -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.7);
  box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.7);
}
</style>