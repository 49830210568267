<template>
  <v-container fluid class="accent-border-top">
    <v-row>
      <v-col cols="6" md="2" v-if="backLinkTo">
        <node-step
          :step="{
            is: 'omni-btn',
            options: { block: true, small: true },
            event: { type: 'node-link', linkTo: backLinkTo },
          }"
          @on-event="onEvent"
        >
          Voltar
        </node-step>
      </v-col>
      <v-spacer v-if="!$vuetify.breakpoint.mobile" />
      <v-col cols="6" md="2" v-if="forwardLinkTo">
        <node-step
          :step="{
            is: 'omni-btn',
            options: { block: true, small: true },
            event: { type: 'node-link', linkTo: forwardLinkTo },
          }"
          @on-event="onEvent"
        >
          Avançar
        </node-step>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: ["backLinkTo", "forwardLinkTo"],
  name: "step-bar",
  methods: {
    onEvent(event) {
      this.$emit("on-event", event);
    },
  },
};
</script>