import {
    db
} from "../plugins/firebase";

//track record

export async function sendUserTrackRecord(trackRecord, trackID, userID) {
    await getUserTrackRecordDoc(trackID, userID).set(trackRecord, { merge: true })
}

export async function fecthUserTrackRecord(trackID, userID) {
    try {
        let snap = await getUserTrackRecordDoc(trackID, userID).get();
        let trackRecord = snap.exists ? snap.data() : null;

        var lsData = getUserTrackRecordFromStorageData(trackID, userID);
        if (lsData && trackRecord)
            trackRecord = { ...trackRecord, ...lsData };

        if (!trackRecord) {
            trackRecord = newTrackRecord();
            await sendUserTrackRecord(trackRecord, trackID, userID);
        }

        return trackRecord;
    } catch (error) {
        console.log(error)
    }
}

function getUserTrackRecordDoc(trackID, userID) {
    return db.collection("trackRecord").doc(trackID).collection("userTrackRecord").doc(userID)
}

function getUserTrackRecordFromStorageData(trackID, userID) {
    var id = getLocalStorageId(trackID, userID)
    var data = localStorage.getItem(id) ? JSON.parse(localStorage.getItem(id)) : null;
    return data
}

export async function setUserTrackRecordToStorageData(trackRecord, trackID, userID) {
    var id = getLocalStorageId(trackID, userID)
    await localStorage.setItem(id, JSON.stringify(trackRecord))
}

function getLocalStorageId(trackID, userID) {
    return userID + "_" + trackID;
}

function newTrackRecord() {
    return {
        status: "open",
        openAt: Date.now(),
        currentViewID: null,
        previousViewID: null,
        data: {},
        navigation: [],
        validElements: {}
    }
}

//track data

export async function fetchViews(trackID) {
    try {
        let views = {}
        let snap = await getViewCollection(trackID).get();
        snap.docs.forEach(doc => views[doc.id] = doc.data())
        return views;

    } catch (error) {
        console.log(error)
    }
}

function getViewCollection(trackID) {
    return db.collection("tracks").doc(trackID).collection("views")
}

export async function fetchView(trackID, viewID) {
    try {
        let doc = await getViewDoc(trackID, viewID).get();
        return doc.data();
    } catch (error) {
        console.log(error)
    }
}

export function getViewDoc(trackID, viewID) {
    return db.collection("tracks").doc(trackID).collection("views").doc(viewID)
}

// let props = []

export async function fetchTrack(trackID) {
    try {
        let data = {}
        let dataDocs = await getTrackDoc(trackID).collection("data").get();
        dataDocs.forEach(doc => { data[doc.id] = doc.data() })

        let views = {}
        let viewDocs = await getTrackDoc(trackID).collection("views").get();
        viewDocs.forEach(doc => { views[doc.id] = doc.data() });

        return { id: trackID, data: data, views: views };
    } catch (error) {
        console.log(error)
    }
}

// function fetchTrackProps(views) {
//     props = []
//     Object.keys(views).forEach(key => { findProp(views[key]); })
//     return props;
// }

// function findProp(component) {
//     component.nodes.forEach(node => {
//         let elementData = null
//         if (node.component.options != null && node.component.options["elementData"] != null)
//             elementData = node.component.options["elementData"]
//         if (elementData != null)
//             props.push(elementData);
//         if (node.nodes != null && node.nodes.length > 0)
//             return findProp(node);
//     });
// }

export function getTrackDoc(trackID) {
    return db.collection("tracks").doc(trackID);
}

