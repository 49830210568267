import { db, auth } from "../plugins/firebase";

export async function setUserInitData(user) {
    let doc = await db.collection("users").doc(user.uid).get();
    if (doc.exists) return;
    await db.collection("users").doc(user.uid).set({
        email: user.email,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        hasCompleteProfile: false
    });
}

export async function updateEmail(email, successCallback) {
    try {
        await auth.currentUser.updateEmail(email)
        successCallback();
    }
    catch (error) {
        console.log(error)
    }
}

export async function updateProfile(form, successCallback) {
    try {
        await db.collection("users").doc(auth.currentUser.uid).set({
            ...form,
            updatedAt: Date.now()
        }, { merge: true });
        successCallback();
    }
    catch (error) {
        console.log(error)
    }
}

export async function fetchUserData(userID) {
    let doc = await db.collection("users").doc(userID).get()
    return doc.data();
}

export async function getUserRoles() {
    const { claims } = await auth.currentUser.getIdTokenResult()

    let roles = {};

    Object.keys(claims).forEach(key => {
        if (allRoles[key] != null) {
            roles[key] = {
                ...allRoles[key], active: claims[key]
            }
        }
    })
    return roles
}

const allRoles = {
    admin:
        { name: "Administrador" }
}