const getters = {
    trackID(state) {
        return state.track.id;
    },
    trackRecord(state) {
        return state.track.user.trackRecord;
    },
    viewID(state) {
        return state.track.user.trackRecord.currentViewID
    },
    previousViewID(state) {
        return state.track.user.trackRecord.previousViewID
    },
    view(state) {
        return state.track.views != null ? state.track.views[state.track.user.trackRecord.currentViewID] : null;
    },
    trackViews(state) {
        return state.track.views
    },
    trackData(state) {
        return state.track.data
    },
    viewValidState(state) {
        return state.track.state.validView;
    },
    validElements(state) {
        return state.track.user.trackRecord.validElements;
    },
    trackStatus(state) {
        return state.track.user.trackRecord.status
    },
    readyToComplete(state) {
        if (state.track.user.trackRecord.status !== "open") return false;
        if (!state.track.user.trackRecord["validElements"]) return false;
        let ready = true;
        let requiredProps = state.track.data.context.requiredPropsToComplete;
        requiredProps.forEach(prop => {
            let found = false;
            if (state.track.user.trackRecord.validElements[prop] === true)
                found = true;
            if (!found) ready = false;
        });
        return ready;
    },
    chapters(state) {
        return state.track.data.chapters
    },
    context(state) {
        return state.track.data.context;
    },
    info(state) {
        return state.track.data.info;
    },
    navigation(state) {
        return state.track.user.trackRecord.navigation;
    },
}
export default getters;
