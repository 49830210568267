var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"accent-border-top",attrs:{"fluid":""}},[_c('v-row',[(_vm.backLinkTo)?_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('node-step',{attrs:{"step":{
          is: 'omni-btn',
          options: { block: true, small: true },
          event: { type: 'node-link', linkTo: _vm.backLinkTo },
        }},on:{"on-event":_vm.onEvent}},[_vm._v(" Voltar ")])],1):_vm._e(),(!_vm.$vuetify.breakpoint.mobile)?_c('v-spacer'):_vm._e(),(_vm.forwardLinkTo)?_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('node-step',{attrs:{"step":{
          is: 'omni-btn',
          options: { block: true, small: true },
          event: { type: 'node-link', linkTo: _vm.forwardLinkTo },
        }},on:{"on-event":_vm.onEvent}},[_vm._v(" Avançar ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }