import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#2C5D99",
                secondary: '#59B08E',
                blue: "#5271FF",
                red: "#DA363D",
                yellow: "#FAC55C",
                accent: "#113860",
                success: '#59B08E',
                error: "#DA363D",
                bg: "#EEE7CE"
            }
        },
        options: {
            customProperties: true
        }
    },
    breakpoint: {
        mobileBreakpoint: 'sm'
    },
});